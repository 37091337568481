import instance, { get, post } from "./request";
import axios from "axios";
import { getFeature } from "./feature";
import { pathOr } from "ramda";
import { getEnv } from "../utils/constants";
import * as msal from "@azure/msal-browser";
import configureBugherd from "_shared/configureBugherd";
// import { signOut } from "_shared/authRedirect";

export let isAuthenticated = false;
export let user = {};
export let RefreshToken;
export let JwtToken;
export let msalJwtToken;
export let msalJwtIdToken;
export let username;
export let checkedForLegacy = false;
const setCheckedForLegacy = (value) => {
  checkedForLegacy = value;
};

const MSAL_AUTH_CONFIG = {
  ["my.appliedmedical.com"]: {
    clientId: "87541d4d-c9ec-4dad-bc48-c528b710b0a9",
    redirectUri: "https://my.appliedmedical.com/login",
    scope: "api://28635a51-67d6-4838-9b09-0039cb0f5475/user_impersonation",
  },
  ["applied7dev.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied7dev.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa1.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa1.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa2.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa2.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa3.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa3.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa4.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa4.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa5.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa5.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa6.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa6.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa7.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa7.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa8.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa8.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa9.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa9.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["myappliedqa10.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://myappliedqa10.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa1.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa1.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa2.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa2.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa3.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa3.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa4.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa4.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa5.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa5.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa6.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa6.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
    // scope: "api://d0b34ee0-9c61-46ea-9dc8-2070b73a5052/user_impersonation", //for por testing for dev SAPproxy server
  },
  ["appliedqa7.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa7.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
    // scope: "api://d0b34ee0-9c61-46ea-9dc8-2070b73a5052/user_impersonation", //for por testing for dev SAPproxy server
  },
  ["appliedqa8.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa8.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa9.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa9.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied10qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied10qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied11qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied11qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied12qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied12qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied13qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied13qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa13.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa13.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedqa14.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedqa14.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied14qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied14qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  //NEW CONTAINERS ON QA SUB
  ["applied1qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied1qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied2qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied2qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied3qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied3qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied4qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied4qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied5qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied5qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied6qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied6qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied7qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied7qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied8qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied8qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied9qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied9qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied10qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied10qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied11qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied11qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied12qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied12qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied13qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied13qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied14qa.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied14qa.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied15qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied15qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied16qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied16qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied17qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied17qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied18qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied18qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied19qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied19qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied20qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied20qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied21qa.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied21qa.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc1.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc1.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc2.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc2.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc3.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc3.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc4.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc4.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc5.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc5.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc6.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc6.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
    // scope: "api://d0b34ee0-9c61-46ea-9dc8-2070b73a5052/user_impersonation", //for por testing for dev SAPproxy server, switch back when merging to master
  },
  ["appliedpoc7.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc7.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc8.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc8.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc9.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedpoc9.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  //NEW CONTAINERS IN DEV SUB
  ["applied1poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied1poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied2poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied2poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied3poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied3poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied4poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied4poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied5poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied5poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied6poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied6poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied7poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied7poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied8poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied8poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied9poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied9poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied10poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied10poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied11poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied11poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["appliedpoc12.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied12poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied13poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied13poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied14poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied14poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied15poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied15poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied16poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied16poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied17poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied17poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied18poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied18poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied19poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied19poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied20poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied20poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applied21poc.z1.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applied21poc.z1.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev1.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev1.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev2.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev2.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev3.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev3.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev4.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev4.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev5.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev5.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev6.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev6.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev7.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev7.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev8.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev8.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev9.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev9.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["applieddev10.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://applieddev10.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
  ["localhost"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirect: "http://localhost:8080/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
    // scope: "api://d0b34ee0-9c61-46ea-9dc8-2070b73a5052/user_impersonation", //for por testing for dev SAPproxy server
  },
  ["appliedprodtest.z22.web.core.windows.net"]: {
    clientId: "5424a928-3344-415c-9ab4-38e2657f5061",
    redirectUri: "https://appliedprodtest.z22.web.core.windows.net/login",
    scope: "api://6f49c73d-4b09-4990-bea2-d717f98d5afa/user_impersonation",
  },
};

const CURRENT_MSAL_AUTH_CONFIG = MSAL_AUTH_CONFIG[window.location.hostname];

export const loginRequest = { scopes: [CURRENT_MSAL_AUTH_CONFIG.scope] };

// Add here the scopes to request when obtaining an access token for MS Graph API
export const tokenRequest = {
  scopes: [CURRENT_MSAL_AUTH_CONFIG.scope],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const msalConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/appliedmed.com",
    clientId: CURRENT_MSAL_AUTH_CONFIG.clientId,
    redirectUri: CURRENT_MSAL_AUTH_CONFIG.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    // cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const setUsername = (name) => {
  username = name;
};

export const setUserAccount = (resp) => {
  console.log("setUserAccount", resp);
  username = resp.account;
  username = resp.username;
};

const setUser = (userObject) => {
  if (userObject.CostCenter === "") {
    console.log({ username });
    // signOut(username);
  } else {
    user = userObject;
    // configureBugherd(user);
  }
};

export const isITorIS = () =>
  user.CostCenter === "101040" || user.CostCenter === "101045";

export const updateGlobalJWT = (jwt) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  JwtToken = jwt;
};

export const updateMsalJWT = ({ accessToken, idToken }) => {
  msalJwtToken = accessToken;
  msalJwtIdToken = idToken;
  console.log("msalTokens", { accessToken, idToken });
};

// export const updateMsalIDJWT = (jwt) => {
//   msalJwtIDToken = jwt;
//   console.log({ msalJwtIDToken });
// };

// export const AUTHBASEURL = "https://apidev.appliedmed.com/Auth";
// export const AUTHBASEURL = "https://api.appliedmed.com/AuthWill";
// export const AUTHBASEURL = AUTHBASEURL;

export const setRefreshToken = (string) => {
  RefreshToken = string;
};

export const setIsAuthenticated = (bool) => {
  isAuthenticated = bool;
};

const getHasuraJwtAndSetFeatures = async (TeamId, CostCenter) => {
  try {
    await getFeature(TeamId, CostCenter);
  } catch (e) {
    console.log(e);
  }
};

export const windowsAuth = async ({ clientId }) => {
  console.log("windowsAUthClientId", clientId);
  try {
    const {
      data: { jwtToken, refreshToken },
    } = await post({
      url: `${getEnv("AUTHBASEURL")}/api/WindowsAuthentication`,
      data: { clientId },
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return JSON.stringify(data);
        },
      ],
    });
    console.log({ jwtTokenFromWindowsAuth: jwtToken });
    return jwtToken;
  } catch (e) {
    throw e;
  }
};

export const acquireLegacyJwt = async (msalRespObj) => {
  console.log("being fired");
  const { idToken } = msalRespObj;
  console.log({ msalRespObjInacquireLegacyJwt: msalRespObj });
  try {
    const url = `${getEnv("AUTHBASEURL")}/api/AzureAuthentication`;
    console.log({ url });
    const {
      data: { jwtToken, refreshToken },
    } = await post({
      url,
      data: { clientId: "MyApplied" },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    });
    console.log({ jwtToken, refreshToken });
    if (jwtToken && refreshToken) {
      updateGlobalJWT(`${jwtToken}`);
      setRefreshToken(refreshToken);
      setCheckedForLegacy(true);
      const { data } = await get({
        url: "authenticate",
      });
      if (data) {
        setUser(data);
        isAuthenticated = true;
        getHasuraJwtAndSetFeatures(data.TeamId, data.CostCenter);
        if (pathOr(false, ["Appcues", "identify"], window)) {
          window.Appcues.identify(data.TeamId, { CostCenter: data.CostCenter });
        }
      }
      return data;
    }
  } catch (e) {
    console.log("acquireLegacyJwt error: ", e);
    setCheckedForLegacy(true);
    throw e; // throwing e so it can be caught in loginProcess
  }
};

export const login = async () => {
  try {
    const {
      data: { jwtToken, refreshToken },
    } = await post({
      url: `${getEnv("AUTHBASEURL")}/api/WindowsAuthentication`,
      data: { clientId: "MyApplied" },
      withCredentials: true,
    });
    if (jwtToken && refreshToken) {
      updateGlobalJWT(`${jwtToken}`);
      setRefreshToken(refreshToken);
    }
    const { data } = await get({ url: "authenticate" });
    if (pathOr(false, ["Appcues", "identify"], window)) {
      window.Appcues.identify(data.TeamId, { CostCenter: data.CostCenter });
    }
    if (data) {
      setUser(data);
      isAuthenticated = true;
      getHasuraJwtAndSetFeatures(data.TeamId, data.CostCenter);
    }
    return data;
  } catch (e) {
    console.log({ e });
    throw e; // throwing e so it can be caught in loginProcess
  }
};

export const jwtLogin =
  ({ username, password }) =>
  async () => {
    try {
      const {
        data: { jwtToken, refreshToken },
      } = await post({
        url: `${getEnv("AUTHBASEURL")}/api/Authentication`,
        data: { username, password, clientId: "MyApplied" },
      });
      if (jwtToken && refreshToken) {
        // instance.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
        updateGlobalJWT(jwtToken);
        setRefreshToken(refreshToken);
      }
      const { data } = await get({ url: "authenticate" });
      if (pathOr(false, ["Appcues", "identify"], window)) {
        window.Appcues.identify(data.TeamId, { CostCenter: data.CostCenter });
      }
      if (data) {
        setUser(data);
        isAuthenticated = true;
        // await getHasuraJwt();
        getHasuraJwtAndSetFeatures(data.TeamId, data.CostCenter);
      }
      return data;
    } catch (e) {
      console.log({ e });
      throw e; // throwing e so it can be caught in loginProcess
      // return e;
    }
  };

export const signout = (cb) => {
  cb();
  isAuthenticated = false;
};
